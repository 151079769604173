<template>
  <v-dialog :value="supplierModal" persistent fullscreen>
    <v-card class="pb-10">
      <v-card-title>{{ thisSupplier.name }}</v-card-title>
      <v-form v-model="formIsValid" ref="form">
        <v-tabs v-model="tab" show-arrows>
          <v-tab key="Info">Info</v-tab>
          <v-tab @click="fetchOrders" key="Orders">Orders</v-tab>
          <v-tab @click="fetchInvoices" key="Facturen">Facturen</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Info">
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="Naam"
                  ref="name"
                  v-model="thisSupplier.name"
                  :rules="[rules.required]"
                ></v-text-field>              
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  label="Contactpersoon"
                  ref="contact"
                  v-model="thisSupplier.contact"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="Adres"
                  ref="address"
                  v-model="thisSupplier.address"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  label="Postcode"
                  ref="postalcode"
                  :rules="[rules.required]"
                  v-model="thisSupplier.postalcode"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="Stad"
                  ref="city"
                  :rules="[rules.required]"
                  v-model="thisSupplier.city"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  dense
                  label="Land"
                  ref="country"
                  :rules="[rules.required]"
                  v-model="thisSupplier.country"
                  :items="countries"
                  item-text="cname"
                  item-value="ccode"
                  autocomplete="new-password"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="Telefoon"
                  ref="phone"
                  v-model="thisSupplier.phone"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  label="Mobiel"
                  ref="mobile"
                  v-model="thisSupplier.mobile"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="E-mail"
                  ref="email"
                  v-model="thisSupplier.email"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  label="BTW-nummer"
                  ref="VATNumber"
                  v-model="thisSupplier.VATNumber"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="KvK-nummer"
                  ref="COCNumber"
                  v-model="thisSupplier.COCNumber"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  label="Bankrekening"
                  ref="bankAccount"
                  v-model="thisSupplier.bankAccount"
                ></v-text-field>
              </v-col>
              <v-col
                ><v-checkbox
                v-if="$store.state.client.scope==2"
                  label="Actief"
                  v-model="thisSupplier.active"
                ></v-checkbox
              ></v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="Orders">
           <v-data-table
              :headers="orderHeaders"
              :items="orders"
              :page.sync="orderOptions.page"
              :items-per-page.sync="orderOptions.itemsPerPage"
              @update:page="fetchOrders"
              @update:items-per-page="fetchOrders"
              :server-items-length="ordersLength"
              :footer-props="{showFirstLastPage: true}"
              class="elevation-1"
              item-key="_id"
            >
              <template v-slot:[`item.status`]="{ item }">
                <img :src="`/img/${item.status}.svg`" />
              </template>
              <template v-slot:[`item.date`]="{ item }">{{
                toDateString(item.date)
              }}</template>
              <template v-slot:[`item.grossTotal`]="{ item }">{{
                toCurrency(item.grossTotal)
              }}</template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="Facturen">
            <v-data-table
              :headers="invoiceHeaders"
              :items="invoices"
              :page.sync="invoiceOptions.page"
              :items-per-page.sync="invoiceOptions.itemsPerPage"
              @update:page="fetchInvoices"
              @update:items-per-page="fetchInvoices"
              :server-items-length="invoicesLength"
              :footer-props="{showFirstLastPage: true}"
              class="elevation-1"
              item-key="_id"
            >
              <template v-slot:[`item.status`]="{ item }">
                <img :src="`/img/${item.status}.svg`" />
              </template>
              <template v-slot:[`item.date`]="{ item }">{{
                toDateString(item.date)
              }}</template>
              <template v-slot:[`item.dueDate`]="{ item }">{{
               toDateString(item.dueDate)
              }}</template>
              <template v-slot:[`item.grossTotal`]="{ item }">{{
                toCurrency(item.grossTotal)
              }}</template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card>
    <prompt-modal
      v-if="promptModal"
      @confirm="deleteSupplier"
      @cancel="closePromptModal"
      promptType="delete"
      :promptModal="promptModal"
      promptTitle="Verwijder leverancier"
      promptText="Weet u zeker dat u deze leverancier wilt verwijderen?"
    ></prompt-modal>
    <v-footer class="d-flex justify-center">
      <v-btn
        v-if="thisSupplier._id"
        color="primary"
        tile
        @click="openPromptModal()"
        >Verwijder</v-btn
      >      
      <v-btn color="primary" tile @click="closeSupplierModal">Annuleer</v-btn>
      <v-btn color="primary" tile @click="saveSupplier">Opslaan</v-btn>
    </v-footer>
  </v-dialog>
</template>
<script>
import { countries } from "../js/countries"
import promptModal from "../components/promptModal"
import {printPayments} from "../js/pdfController"
import { errorHandler, fetchGET, fetchPOST, successHandler, toDateString, toCurrency } from '../js/functions'
export default {
  name: "supplierModal",
  props: {
    tabNumber: {
      type: Number,
      default: null
    },
    supplier: Object,
    supplierModal: Boolean,
  },
  data() {
    return {
      item: {},
      exportOptions: [{text:'Binnen EU',value:true},{text:'Buiten EU',value:false}],
      printOutstandingInvoicesModal: false,
      emailOutstandingInvoicesModal: false,
      previewOrderModal: false,
      previewInvoiceModal: false,
      dataUrl: "",  
      tab: this.tabNumber,
      thisSupplier: JSON.parse(JSON.stringify(this.supplier)),
      orders: [],
      invoices:[],
      fixedPrices: [],
      openOrders:[],
      outstandingInvoices:[],
      orderNetTotal: 0,
      orderGrossTotal: 0,
      orderDebit: 0,
      orderOptions:{itemsPerPage:10,page:1},
      ordersLength:0,
      invoiceNetTotal: 0,
      invoiceGrossTotal: 0,
      invoiceDebit: 0,
      invoiceOptions:{itemsPerPage: 10,page:1},
      invoicesLength: 0,
      openOrdersHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value:"orderNumber"},
        { text: "Naam", value: "name" },
        { text: "Totaal", value: "grossTotal" },
      ],
      outstandingInvoicesHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Vervaldatum", value: "dueDate" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Betaald", value: "credit" },
        { text: "Openstaand", value: "debit" }
      ],
      invoiceHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Vervaldatum", value: "dueDate" },
        { text: "Totaalbedrag", value: "grossTotal" }
      ],
      orderHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Totaalbedrag", value: "grossTotal" }
      ],
      fixedPricesHeaders: [
        { text: "Artikelnr", value: "itemNumber", filterable: false },
        { text: "Artikel", value: "itemName", filterable: true },
        { text: "Prijs", value: "price", filterable: false },
        { text: "Aangemaakt", value: "created", filterable: false },
        { text: "Gewijzigd", value: "updated", filterable: false },
        { text: "", value: "actions" }
      ],
      paymentHeaders: [
        { text: "Factuurnr", value: "invoiceNumber"},
        { text: "Datum", value: "date" },
        { text: "Bedrag", value: "amount", },
        { text: "Betalingswijze", value: "payMethod"},
      ],
      payments: [],
      allDates: false,
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      fromDatePicker: false,
      toDatePicker: false,
      promptModal: false,
      searchItem: "",
      searchString: "",
      fixedPriceModal: false,
      formIsValid: false,
      rules: {
        required: value => !!value || "Veld verplicht.",
        counter: value => value.length <= 20 || "Max 20 karakters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Onjuist e-mailadres."
        }
      },
      countries: countries
    }
  },
  methods: {
    toCurrency(n){
    return toCurrency(n)
    },
    toDateString(val){
    return toDateString(val)
    },
    changeFromDate(date){
        this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.fetchInvoicePayments()
    },
    changeToDate(date){
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted        
      }
      this.fetchInvoicePayments()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    setItem(item) {
      this.item = item
    },
    setSearchString(val){
      this.searchString = val
    },
    clearInput() {
      this.searchResults = false
    },
    openPromptModal() {
      this.promptModal = true
    },
    closePromptModal() {
      this.promptModal = false
      this.$emit("close-supplier-modal")
    },
    async fetchSupplierAccount(){
      try {
        let response = await fetchGET('supplierAccount',this.thisSupplier.supplierNumber)
        console.log(response)
        this.orderNetTotal = response[0].value[0].netTotal
        this.orderGrossTotal = response[0].value[0].grossTotal
        this.orderDebit = response[0].value[0].debit
        this.invoiceNetTotal = response[1].value[0].netTotal
        this.invoiceGrossTotal = response[1].value[0].grossTotal
        this.invoiceDebit = response[1].value[0].debit
        this.openOrders = response[2].value
        this.outstandingInvoices = response[3].value        
      } catch (e) {
        errorHandler(e,"Kan rekening niet ophalen")
      }
    },
    async fetchOrders(){
try {
  // let response = await fetchGET("purchaseOrders",{supplierNumber:this.thisSupplier.supplierNumber,options:this.orderOptions})
  // console.log(response)
  // this.orders = response[0].results
  // this.ordersLength = response[0].length[0].length
} catch (e) {
  errorHandler(e,"Kan orders niet ophalen")
}
    },
    async fetchInvoices(){
try {
  // let response = await fetchGET("purchaseInvoices",{supplierNumber:this.thisSupplier.supplierNumber,options:this.invoiceOptions})
  // console.log(response)
  // this.invoices = response[0].results
  // this.invoicesLength = response[0].length.length ? response[0].length[0].length : 0
} catch (e) {
  errorHandler(e,"Kan facturen niet ophalen")
}
    },
    saveSupplier() {
      this.$refs.form.validate()
      if (this.formIsValid) {
        if(this.thisSupplier._id){
          this.$emit("update-supplier", this.thisSupplier)
        }
        else this.$emit("insert-supplier", this.thisSupplier)
      }
    },
    deleteSupplier() {
      this.promptModal = false
      this.$emit("delete-supplier", this.thisSupplier._id)
    },
    resetSupplier() {
      this.$refs.form.reset()
    },
    checkForDuplicates(){
      let items = this.fixedPrices
      let index = items.findIndex(item=>item.itemNumber==this.item.itemNumber)
      if(index>-1)return true
      return false
    },
    async fetchFixedPrices(){
      if(this.thisSupplier.supplierNumber){
      try {
        this.fixedPrices = await fetchGET('supplierFixedPrices',this.thisSupplier.supplierNumber)
      } catch (e) {
        errorHandler(e,"Kan prijsafspraken niet ophalen")
      }
      }
    },
    async insertFixedPrice(item,fixedPrice) {
      try {
       let duplicates = this.checkForDuplicates()
    if(!duplicates){
      let data = {
        itemName: item.name,
        itemNumber: item.itemNumber,
        supplierName: this.thisSupplier.name,
        supplierNumber: this.thisSupplier.supplierNumber,
        price: fixedPrice,
        created: new Date()
      }
      let response = await fetchPOST('insertFixedPrice',data)
      if(response.result.n==0) throw "insertFixedPrice"
      this.fixedPrices.push(data)
      successHandler("Prijsafspraak opgeslagen")
    }
      else alert(` ${this.item.name} bestaat al`)
      } catch (e) {
        errorHandler(e,"Prijsafspraak niet opgeslagen")
      } 
    },
    async updateFixedPrice(item) {
      try {
        item.price = item.price.replace(",",".")
      let index = this.fixedPrices.findIndex(fix=>fix.itemNumber==item.itemNumber)
      if(index>-1)
      {
        let response = await fetchPOST('updateFixedPrice',item)
        if(response.result.nModifief==0) throw "updateFixedPrice"
        Object.assign(this.fixedPrices[index],item)}
        successHandler("Prijsafspraak gewijzigd")
      } catch (e) {
        errorHandler(e,"Prijsafspraak niet gewijzigd")
      }
    },
    async deleteFixedPrice(item) {
      try {
        let response = await fetchPOST('deleteFixedPrice',item)
        if(response.result.n==0)throw "deleteFixedPrice"
        let index = this.fixedPrices.findIndex(fix=>fix.itemNumber==item.itemNumber)
      if(index>-1) this.fixedPrices.splice(index,1)
        successHandler("Prijsafspraak verwijderd")
      } catch (e) {
        errorHandler(e,'Prijsafspraak niet verwijderd')
      }
    },
    openFixedPriceModal() {
      this.fixedPriceModal = true
    },
    closeFixedPriceModal() {
      this.fixedPriceModal = false
    },
    closeSupplierModal() {
      this.$emit("close-supplier-modal")
    },
     openPrintOutstandingInvoicesModal(){
    this.printOutstandingInvoicesModal = true
    },
   openEmailOutstandingInvoicesModal(){
    this.emailOutstandingInvoicesModal = true
    },
    openPrintPayments(){
      this.printType = "printPayments"
this.insertInvoiceModal = true
    },
    closePrintInvoiceModal(){
      this.printType = ""
this.insertInvoiceModal = false
    },
    closePrintModal(){
      this.printModal = false
    },
    printPayments(options){
      printPayments(this.thisSupplier,this.payments,options)
      this.closePrintInvoiceModal()
    },
    async fetchInvoicePayments(){
      try {
        let data= {
        supplierNumber:this.thisSupplier.supplierNumber,
        allDates: this.allDates,
        fromDate: this.fromDate,
        toDate: this.toDate,

      }
    this.payments = await fetchGET('invoicePayments',data)
      } catch (e) {
        errorHandler(e,"Kan betalingen niet ophalen")
      }      
    }
  },
  computed: {
    routes(){
      return this.$store.state.settings.routes
    }
  },
  components: {
    promptModal
}
}
</script>
<style>
</style>