    <template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <search-supplier
        :searchString="searchSupplierString"
        :noResults="true"
        @setSearchString ="setSearchSupplierString"
        @get-results="getSupplierResults"
        @clear-input="clearSupplier"
        ></search-supplier>
      </v-col>
      <v-col cols="12" sm="3" class="d-flex justify-end">
        <v-btn @click="openSupplierModal(defaultSupplier)" color="primary">Nieuwe leverancier</v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="supplierHeaders"
        :items="filteredSuppliers"
        item-key="_id"
        :options.sync="options"
        @update:options="fetchSuppliers"
        :server-items-length="suppliersLength"
        :footer-props="{
      'items-per-page-options': [10,25,50,100]}"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{item}">
            <b style="cursor: pointer" @click="openSupplierModal(item)">{{item.name}}</b>
        </template>
      </v-data-table>
    </v-card>
    <supplier-modal
      v-if="supplierModal"
      :supplierModal="supplierModal"
      :supplier="supplier"
      @insert-supplier="insertSupplier"
      @update-supplier="updateSupplier"
      @delete-supplier="deleteSupplier"
      @close-supplier-modal="closeSupplierModal"
    ></supplier-modal>
  </v-container>
</template>
    <script>
import searchSupplier from "../components/searchSupplier.vue"
import supplierModal from "../components/supplierModal"
import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "suppliers",
  data() {
    return {
      supplier: {},
      defaultSupplier: {
        country: "NL",
        payTerms: 0,
        scope: 1,
        active: true,
        sort: 0
      },
      suppliers:[],
      suppliersLength: 0,
      searchSupplierString:"",
      searchResults: [],
      options: { itemsPerPage: 25, page: 1 },
      supplierModal: false,
      supplierHeaders: [
        { text: "Leveranciernr", value: "supplierNumber" },
        { text: "Naam", value: "name" },
        { text: "Telefoon", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Adres", value: "address" },
        { text: "Postcode", value: "postalcode" },
        { text: "Stad", value: "city" },
        { text: "Land", value: "country" }
      ]
    }
  },
  methods: {
    async fetchSuppliers(){
     let data =  {
              options:this.options
          }
let response = await fetchGET('fetchSuppliers',data)
this.suppliers = response.suppliers
this.suppliersLength = response.length
    },
    async openSupplierModal(supplier) {
      this.supplier = supplier
      this.supplierModal = true
    },
     setSearchSupplierString(val){
      this.searchSupplierString = val
    },
    getSupplierResults(suppliers){
      this.searchResults = suppliers
    },
    closeSupplierModal() {
      this.supplier = {}
      this.supplierModal = false
    },
    async deleteSupplier(_id) {
      try {
        let response = await fetchPOST('deleteSupplier',{_id:_id})
        if(response.result.n==0) throw "deleteSupplier"
        successHandler("Leverancier verwijderd")
        this.closeSupplierModal()
      } catch (e) {
        errorHandler(e,"Leverancier niet verwijderd")
      }
      this.supplierModal = false
    },
    async insertSupplier(supplier) {
      try {
        let response = await fetchPOST('insertSupplier',supplier)
        if(response.result.n==0) throw "insertSupplier"
        successHandler("Leverancier opgeslagen")
        this.closeSupplierModal()
      } catch (e) {
        errorHandler(e,"Leverancier niet opgeslagen")
      }
    },
    async updateSupplier(supplier) {
      try {
        let response = await fetchPOST('updateSupplier',supplier)
        if(response.result.nModified==0) throw "updateSupplier"
        successHandler("Leverancier gewijzigd")
        this.closeSupplierModal()
      } catch (e) {
        errorHandler(e,"Leverancier niet gewijzigd")
      }
    },
    clearSupplier(){
    this.searchSupplierString = ""
    this.selectedSupplier = {}
    this.searchResults= []
    this.fetchSuppliers()
},
  },
  computed: {
    filteredSuppliers() {
      if (this.searchResults.length) {
        return this.searchResults
      }
      return this.suppliers
    }
  },
  mounted() {
    this.fetchSuppliers()
  },
  components: { searchSupplier, supplierModal }
}
</script>
<style scoped="">

</style>